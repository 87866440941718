<template>
  <div class="wrapper1">
    <el-row :gutter="20">
      <!--            <el-col :span="3">-->
      <!--                <el-button type="success"-->
      <!--                           @click="addRole"-->
      <!--                           icon="el-icon-plus">新增角色</el-button>-->
      <!--            </el-col>-->
      <el-col :span="3">
        <el-button @click="refresh" icon="el-icon-refresh" type="warning"
          >刷新</el-button
        >
      </el-col>
    </el-row>

    <table-container :list="roleList">
      <el-table-column type="index"> </el-table-column>
      <el-table-column prop="role_id" label="角色ID"> </el-table-column>
      <el-table-column prop="role_name" label="角色名"> </el-table-column>
      <el-table-column prop="remark" label="角色标识码"> </el-table-column>
      <el-table-column prop="user_count" label="角色下用户数量">
      </el-table-column>
      <el-table-column prop="create_time" label="创建日期"> </el-table-column>
      <!--            <el-table-column label="操作">-->
      <!--                <template slot-scope="scope">-->
      <!--                    <el-button type="text"-->
      <!--                               size="mini"-->
      <!--                               icon="el-icon-edit"-->
      <!--                               :disabled="scope.row.user_count>0?true:false"-->
      <!--                               @click="handleDel(scope.$index, scope.row)">删除</el-button>-->
      <!--                </template>-->
      <!--            </el-table-column>-->
    </table-container>
    <el-pagination
      background
      :hide-on-single-page="true"
      :total="totalCount"
      :page-sizes="pageSizes"
      :page-size="pageSize"
      layout="prev, pager, next,sizes"
      @size-change="sizeChange"
      @current-change="currentChange"
    >
    </el-pagination>

    <el-dialog
      title="新增角色"
      :visible.sync="showingDialog"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form
        :label-position="labelPosition"
        label-width="90px"
        :model="curRoleData"
        :rules="curRoleDataRules"
        ref="curRoleDataRef"
      >
        <el-form-item label="ID:" prop="roleId">
          <el-input v-model="curRoleData.roleId" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="角色名:" prop="roleName">
          <el-input v-model.trim="curRoleData.roleName"></el-input>
        </el-form-item>
        <el-form-item label="角色标识码:" prop="remark">
          <el-input v-model.trim="curRoleData.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAdd">取 消</el-button>
        <el-button type="primary" @click="sureAdd">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "index",
  inject: ["reload"],
  data() {
    return {
      labelPosition: "right",
      curPage: 0,
      pageSize: 10,
      pageSizes: [10, 20, 30, 40, 50],
      totalCount: 0,
      roleList: [],
      showingDialog: false,
      curRoleData: {
        roleName: "",
        remark: "",
      },
      curRoleDataRules: {},
    };
  },
  async created() {
    this.getRoleList();
  },
  mounted() {},
  methods: {
    handleDel(index, row) {},
    cancelAdd() {
      this.showingDialog = false;
    },
    sureAdd() {
      this.showingDialog = false;
    },
    sizeChange(size) {
      this.pageSize = size;
      this.getRoleList();
    },
    currentChange(page) {
      this.curPage = page;
      this.getRoleList();
    },
    handleEdit(index, row) {},
    async addRole() {
      this.showingDialog = true;
    },
    refresh() {
      this.reload();
    },
    async getRoleList() {
      let param = {
        page: this.curPage,
        pageSize: this.pageSize,
      };
      let data = await this.$api.sysUserApi.getRoleList(param);
      if (data.code == 0) {
        let d = data;
        this.roleList = d.roles;
        this.totalCount = d.totalCount;
        this.page = d.page;
        this.pageSize = d.pageSize;
      } else if (data.code != 0) {
        if (data.code == 500) {
          this.$message({
            message: data.msg,
            type: "error",
          });
          return;
        }
      }
    },
    newRole() {},
    updateRole() {},
    deleteRole() {},
  },
};
</script>

<style scoped>
</style>
